export { default as QoplaLogoSmall } from "./q.png";
export { default as QoplaLogo } from "./qopla.png";
export { default as SwishLogoPng } from "./swish-logo-primary.png";
export { default as BoltLogoPng } from "./bolt-logo.png";
export { default as UberEatsLogoPng } from "./uber-eats-logo.png";
export { default as WoltLogoPng } from "./wolt-logo.png";
export { default as WoltLogoWPng } from "./wolt-logo-w.png";
export { default as FoodoraLogoPng } from "./foodora-logo.png";
export { default as SubscriptionPng } from "./sub_default.png";
export { default as BoltLogoWhitePng } from "./bolt-logo-white.png";
export { default as SantaHat } from "./santa-hat.png";
export { default as MarketplaceKlarnaLogo } from "./market_klarna.png";

export const Menigo = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/menigo.png";
export const Nets = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/nets.png";
export const Qred = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/qred.png";
export const Froda = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/froda.png";
export const PersonalKollen = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/personalkollen.png";
export const Caspeco = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/caspeco.png";
export const Soderberg = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/images/soderberg.png";
/** back office flags https://www.flaticon.com/free-icon */
